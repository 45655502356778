export const CUSTOM = {
  label: 'Custom',
  value: 'CUSTOM'
};
export const SMALL = {
  label: 'Small',
  value: 'SMALL'
};
// export const MEDIUM = {
//   label: 'Medium',
//   value: 'MEDIUM'
// };
export const LARGE = {
  label: 'Large',
  value: 'LARGE'
};
export const XLARGE = {
  label: 'Extra Large',
  value: 'XLARGE'
};
export const SMALLER = {
  label: 'Smaller',
  value: 'SMALLER'
};
export const LARGER = {
  label: 'Larger',
  value: 'LARGER'
};
export const CENTER = {
  label: 'Center',
  value: 'CENTER'
};
export const THREE_EIGHTHS_INCH = {
  label: '3/8"',
  value: 'THREE_EIGHTHS_INCH'
};
export const ONE_HALF_INCH = {
  label: '1/2"',
  value: 'ONE_HALF_INCH'
};
export const FIVE_EIGHTHS_INCH = {
  label: '5/8"',
  value: 'FIVE_EIGHTHS_INCH'
};
export const THREE_FOURTHS_INCH = {
  label: '3/4"',
  value: 'THREE_FOURTHS_INCH'
};
export const TWO_BY_FOUR = {
  label: '2x4 (1-1/2" x 3-1/2")',
  value: 'TWO_BY_FOUR'
};
export const TWO_BY_SIX = {
  label: '2x6 (1-1/2" x 5-1/2")',
  value: 'TWO_BY_SIX'
};
export const TWO_BY_EIGHT = {
  label: '2x8 (1-1/2" x 7-1/4")',
  value: 'TWO_BY_EIGHT'
};
export const TWO_BY_TEN = {
  label: '2x10 (1-1/2" x 9-1/4")',
  value: 'TWO_BY_TEN'
};
export const TWO_BY_TWELVE = {
  label: '2x12 (1-1/2" x 11-1/4")',
  value: 'TWO_BY_TWELVE'
};
